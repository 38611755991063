import * as React from 'react';
import { useCarQualitySidebarQuery } from 'gql/graphql';
import { useProgress } from 'pages/CarQuality/hooks/useProgress';
import { mapDataToFormValues } from 'pages/CarQuality/helpers';
import { mapDataToFormValues as mapAccidentsToForm } from 'pages/Accidents/helpers';
import { useInspection } from 'shared/components/InspectionContext';

function useCarQualityProgress(inspectionId: number) {
  const { shouldUseNewAccidentModel } = useInspection();
  const { data } = useCarQualitySidebarQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setCarQualityProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    if (shouldUseNewAccidentModel) {
      setCarQualityProgress(
        mapDataToFormValues(data?.inspection, shouldUseNewAccidentModel),
        {
          accidentsForm: mapAccidentsToForm(data?.inspection),
          damages: data?.inspection?.vehicle?.damages ?? [],
          accidentAreaOptions:
            data?.inspection?.fields?.vehicle?.accidents.areas ?? [],
        },
      );
    } else {
      setCarQualityProgress(
        mapDataToFormValues(data?.inspection, shouldUseNewAccidentModel),
      );
    }
  }, [data, setCarQualityProgress, shouldUseNewAccidentModel]);

  React.useEffect(() => {
    updateProgress();
  }, [data, setCarQualityProgress, updateProgress]);

  return updateProgress;
}

export { useCarQualityProgress };
