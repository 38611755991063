const PagesEnum = Object.freeze({
  CAR_DETAILS: 'CAR_DETAILS',
  TEST_DRIVE: 'TEST_DRIVE',
  CAR_PICTURES: 'CAR_PICTURES',
  CAR_QUALITY: 'CAR_QUALITY',
  TIRES_AND_BRAKES: 'TIRES_AND_BRAKES',
  CAR_STATUS: 'CAR_STATUS',
  SERVICE_AND_DOCUMENTS: 'SERVICE_AND_DOCUMENTS',
});

const RoutesEnum = Object.freeze({
  CAR_DETAILS: 'CAR_DETAILS',
  CAR_DETAILS_CAR_INFO: 'CAR_DETAILS_CAR_INFO',
  CAR_DETAILS_ADDITIONAL_INFO: 'CAR_DETAILS_ADDITIONAL_INFO',
  CAR_DETAILS_ELECTRIC_CAR_INFO: 'CAR_DETAILS_ELECTRIC_CAR_INFO',
  TEST_DRIVE: 'TEST_DRIVE',
  TEST_DRIVE_MILEAGE: 'TEST_DRIVE_MILEAGE',
  TEST_DRIVE_TEST_DRIVE: 'TEST_DRIVE_TEST_DRIVE',
  TEST_DRIVE_FINDINGS: 'TEST_DRIVE_FINDINGS',
  CAR_PICTURES: 'CAR_PICTURES',
  CAR_PICTURES_MAIN_PHOTOS: 'CAR_PICTURES_MAIN_PHOTOS',
  CAR_PICTURES_ADDITIONAL_PHOTOS: 'CAR_PICTURES_ADDITIONAL_PHOTOS',
  CAR_PICTURES_HIGHLIGHTS: 'CAR_PICTURES_HIGHLIGHTS',
  CAR_QUALITY: 'CAR_QUALITY',
  CAR_QUALITY_THICKNESS: 'CAR_QUALITY_THICKNESS',
  CAR_QUALITY_DAMAGES: 'CAR_QUALITY_DAMAGES',
  CAR_QUALITY_HIGHLIGHTS: 'CAR_QUALITY_HIGHLIGHTS',
  CAR_QUALITY_ACCIDENTS: 'CAR_QUALITY_ACCIDENTS',
  ACCIDENTS: 'ACCIDENTS',
  ACCIDENTS_ACCIDENTS: 'ACCIDENTS_ACCIDENTS',
  ACCIDENTS_AREAS: 'ACCIDENTS_AREAS',
  ACCIDENTS_REPAIR: 'ACCIDENTS_REPAIR',
  TIRES_AND_BRAKES: 'TIRES_AND_BRAKES',
  TIRES_AND_BRAKES_INSTALLED_WHEELS: 'TIRES_AND_BRAKES_INSTALLED_WHEELS',
  TIRES_AND_BRAKES_EXTRA_WHEELS: 'TIRES_AND_BRAKES_EXTRA_WHEELS',
  CAR_STATUS: 'CAR_STATUS',
  CAR_STATUS_EQUIPMENTS: 'CAR_STATUS_EQUIPMENTS',
  CAR_STATUS_OTHER_EQUIPMENTS: 'CAR_STATUS_OTHER_EQUIPMENTS',
  CAR_STATUS_OTHER_INFORMATION: 'CAR_STATUS_OTHER_INFORMATION',
  SERVICE_AND_DOCUMENTS: 'SERVICE_AND_DOCUMENTS',
  SERVICE_AND_DOCUMENTS_SERVICE: 'SERVICE_AND_DOCUMENTS_SERVICE',
  SERVICE_AND_DOCUMENTS_ACCIDENTS: 'SERVICE_AND_DOCUMENTS_ACCIDENTS',
  SERVICE_AND_DOCUMENTS_OTHER_DOCUMENTS:
    'SERVICE_AND_DOCUMENTS_OTHER_DOCUMENTS',
  SUMMARY: 'SUMMARY',
  CHOOSE_AUCTION: 'CHOOSE_AUCTION',
});

const SectionsEnum = Object.freeze({
  CAR_DETAILS: 'carDetails',
  CAR_DETAILS_CAR_INFO: 'carInformation',
  CAR_DETAILS_ADDITIONAL_INFO: 'additionalInformation',
  CAR_DETAILS_ELECTRIC_CAR_INFO: 'electricCarInfo',
  TEST_DRIVE: 'testDrive',
  TEST_DRIVE_MILEAGE: 'mileage',
  TEST_DRIVE_TEST_DRIVE: 'testDrive',
  TEST_DRIVE_FINDINGS: 'testDriveFindings',
  CAR_PICTURES: 'carPhotos',
  CAR_QUALITY: 'carQuality',
  CAR_QUALITY_THICKNESS: 'paintThickness',
  CAR_QUALITY_DAMAGES: 'damages',
  CAR_QUALITY_HIGHLIGHTS: 'highlights',
  CAR_QUALITY_ACCIDENTS: 'accidents',
  TIRES_AND_BRAKES: 'wheels',
  TIRES_AND_BRAKES_INSTALLED_WHEELS: 'installedWheels',
  TIRES_AND_BRAKES_EXTRA_WHEELS: 'extraSetOfWheels',
  CAR_STATUS: 'equipment',
  CAR_STATUS_EQUIPMENTS: 'equipment',
  CAR_STATUS_OTHER_EQUIPMENTS: 'otherEquipment',
  CAR_STATUS_OTHER_INFORMATION: 'otherInformation',
  SERVICE_AND_DOCUMENTS: 'serviceAndDocuments',
  SERVICE_AND_DOCUMENTS_SERVICE: 'service',
  SERVICE_AND_DOCUMENTS_ACCIDENTS: 'accidents',
  SERVICE_AND_DOCUMENTS_OTHER_DOCUMENTS: 'otherDocuments',
  SUMMARY: 'summary',
});

const mapPathToRoute = Object.freeze({
  carDetails: RoutesEnum.CAR_DETAILS,
  testDrive: RoutesEnum.TEST_DRIVE,
  carPictures: RoutesEnum.CAR_PICTURES,
  carQuality: RoutesEnum.CAR_QUALITY,
  accidents: RoutesEnum.ACCIDENTS,
  tiresBrakes: RoutesEnum.TIRES_AND_BRAKES,
  carStatus: RoutesEnum.CAR_STATUS,
  serviceDocuments: RoutesEnum.SERVICE_AND_DOCUMENTS,
});

export { RoutesEnum, SectionsEnum, mapPathToRoute, PagesEnum };
