import { APP_NAMES } from 'config/urls';
import { APP_NAME, DEFAULT_LOCALE, SUPPORTED_LOCALES } from './language/i18n';
import { localStorageService } from './config/localStorageService';

const auto1Path =
  process.env.REACT_APP_NAME === APP_NAMES.MOBILE_EVALUATOR
    ? 'inspection'
    : 'app/merchant-evaluation/inspection';

const routePreText =
  process.env.REACT_APP_NAME === APP_NAMES.MOBILE_EVALUATOR
    ? `/:lang/${auto1Path}`
    : `(/app_dev.php)?/:lang/${auto1Path}`;

export const getLanguage = () => {
  const [, language] = window.location.pathname.split('/');

  if (language in SUPPORTED_LOCALES) {
    localStorageService.setItem(`cache.language.${APP_NAME}`, language);
    return language;
  }
  const languageFromLocalStorage = localStorageService.getItem(
    `cache.language.${APP_NAME}`,
  );
  return languageFromLocalStorage || DEFAULT_LOCALE;
};

const getRoute = (
  routePath: string,
  inspectionId?: string | null,
  isRelative?: boolean,
) => {
  const language = getLanguage();

  if (!isRelative) {
    if (inspectionId === null) {
      return `${routePreText}/${routePath}`;
    }
    return `${routePreText}/${routePath}/:inspectionId`;
  }
  if (inspectionId === null) {
    return `/${language}/${auto1Path}/${routePath}`;
  }
  return `/${language}/${auto1Path}/${routePath}/${inspectionId}`;
};

const getPHPMainRoute = (routePath: string) => {
  const language = getLanguage();
  return `/${language}/app/${routePath}`;
};

export const appRoutes = {
  login: () => `/`,
  notFound: () => `/${getLanguage()}/404`,
  errorMessagePage: (isRelative?: boolean) =>
    getRoute('error', null, isRelative),
  inspectionList: () => `/${getLanguage()}/inspectionList`,
  locationsList: () => getPHPMainRoute(`remarketing-branches`),
  editLocation: (id: string = ':id') =>
    getPHPMainRoute(`remarketing-branches/edit/${id}`),
  addLocation: () => getPHPMainRoute('remarketing-branches/new'),
  carInfo: (isRelative?: boolean) => getRoute('carInfo', null, isRelative),
  pdfInspectionCarInfo: () => `/${getLanguage()}/pdf-inspection/carInfo`,
  carDetails: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('carDetails', inspectionId, isRelative),
  stockNumberRedirection: (stockNumber?: string, isRelative?: boolean) =>
    getRoute('stock', stockNumber, isRelative),
  manualFlow: (inspectionId?: string, isRelative?: boolean) =>
    !isRelative
      ? `${routePreText}/carDetails/:inspectionId/manual-flow`
      : `/${getLanguage()}/${auto1Path}/carDetails/${inspectionId}/manual-flow`,
  manualFlowWithBranch: (branchId?: string, isRelative?: boolean) =>
    !isRelative
      ? `${routePreText}/carDetails/branch/:branchId/manual-flow`
      : `/${getLanguage()}/${auto1Path}/carDetails/branch/${branchId}/manual-flow`,
  testDrive: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('testDrive', inspectionId, isRelative),
  carPictures: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('carPictures', inspectionId, isRelative),
  carQuality: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('carQuality', inspectionId, isRelative),
  accidents: (inspectionId?: string, isRelative?: boolean) =>
    `${getRoute('carQuality', inspectionId, isRelative)}#accidents`,
  tiresBrakes: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('tiresBrakes', inspectionId, isRelative),
  carStatus: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('carStatus', inspectionId, isRelative),
  serviceDocuments: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('serviceDocuments', inspectionId, isRelative),
  summary: (inspectionId?: string, isRelative?: boolean) =>
    getRoute('summary', inspectionId, isRelative),
  completed: (isRelative?: boolean) => getRoute('completed', null, isRelative),
  termsAndConditions: (country: string, isMobileEvaluator: boolean) =>
    isMobileEvaluator
      ? `https://auto1.com/${country}/terms`
      : `/${country}/terms`,
  remarketing: () =>
    getPHPMainRoute('my-cars/evaluation-drafts?page=0&status=in-review'),
  unfinishedEvaluations: () =>
    getPHPMainRoute('my-cars/evaluation-drafts?page=0&status=incomplete'),
  searchRemarketing: (stockNumberOrVin: string) =>
    getPHPMainRoute(`my-cars/search?stockNumberOrVin=${stockNumberOrVin}`),
  auctionEnded: () =>
    getPHPMainRoute('my-cars/remarketing?page=0&status=expired'),
  runningAuctions: () =>
    getPHPMainRoute('my-cars/remarketing?page=0&status=running-auctions'),
  convertToFullEva: (stockNumber?: string, isRelative?: boolean) =>
    getRoute('convertToFullEva', stockNumber, isRelative),
};
