import * as React from 'react';
import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { Skeleton } from 'shared/components/Skeleton';
import styles from './index.module.scss';

const DamagesPlaceholder: React.FC = () => (
  <Card paddingTop={32} paddingBottom={32} showBottomBorder>
    <Typography variant="titleXL" tag="div" additonalClassNames={styles.title}>
      <Skeleton width="98px" height="100%" />
    </Typography>
    <Typography tag="div" additonalClassNames={styles.subtitle}>
      <Skeleton width="344px" height="100%" />
    </Typography>
    <Skeleton width="160px" height="40px" />
  </Card>
);

export { DamagesPlaceholder };
