import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Accidents } from 'pages/Accidents';
import { CarQuality as CarQualityComponent } from './CarQuality';

const CarQuality: React.FC = (props) => {
  const location = useLocation();

  if (location.hash === '#accidents') {
    return <Accidents {...props} />;
  }

  return <CarQualityComponent {...props} />;
};

export { CarQuality };
