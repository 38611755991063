import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '@auto1-ui/button';

import { Card } from 'shared/components/Card';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { InfoInCircle } from 'shared/components/Icons';

import { DamageItem } from '../components/DamageItem';
import { CarQualityItemContainer } from '../components/CarQualityItemContainer';

import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
  paintThicknessVersion?: number;
};

const Damages: React.FC<Props> = ({
  qaIdPrefix,
  paintThicknessVersion = 1,
}) => {
  const { translations } = useTranslation();
  const { watch } = useFormContext();

  const {
    fields: damages,
    append,
    remove,
  } = useFieldArray({
    name: 'damages',
  });
  const isPaintThicknessChecked = watch(
    'paintThickness.isPaintThicknessChecked',
  );

  return (
    <Card
      paddingTop={32}
      paddingBottom={32}
      id="damages"
      qaIdPrefix={`${qaIdPrefix}-container`}
      showBottomBorder
    >
      {paintThicknessVersion === 2 && isPaintThicknessChecked && (
        <Typography
          tag="div"
          data-qa-id={`${qaIdPrefix}-paint-thickness-subtitle`}
          additonalClassNames={styles.descriptionInfo}
        >
          <InfoInCircle width={20} height={20} style={{ marginRight: '8px' }} />
          {translations.DAMAGES_DESCRIPTION_INFO}
        </Typography>
      )}

      <Typography
        variant="titleXL"
        data-qa-id={`${qaIdPrefix}-damages-title`}
        tag="div"
        additonalClassNames={styles.title}
      >
        {translations.DAMAGES_HEADER}
      </Typography>

      <Typography
        tag="div"
        data-qa-id={`${qaIdPrefix}-damages-subtitle`}
        additonalClassNames={styles.subtitle}
      >
        {translations.DAMAGES_DESCRIPTION}
      </Typography>
      {damages.map((damage, index) => (
        <CarQualityItemContainer
          key={damage.id}
          title={`${translations.QUALITY_DAMAGE} #${index + 1}`}
          initialState
          onDelete={() => remove(index)}
          qaIdPrefix={`${qaIdPrefix}-damages-item-container-${index}`}
        >
          <DamageItem
            index={index}
            qaIdPrefix={`${qaIdPrefix}-damages-item-${index}`}
            fieldName={`damages[${index}]`}
          />
        </CarQualityItemContainer>
      ))}
      <Button
        theme="outlined"
        isActivated={false}
        qaId={`${qaIdPrefix}-add-button`}
        extraClass={styles.button}
        onClick={() => {
          append({
            files: [],
            area: null,
            type: null,
            part: null,
            isAccidentReason: false,
          });
        }}
      >
        + {translations.DAMAGES_ADD_BUTTON}
      </Button>
    </Card>
  );
};

export { Damages };
