import {
  requiredString,
  requiredToggle,
  getRequiredMessage,
} from 'shared/utils/yupValidationHelpers';
import * as yup from 'yup';
import { ObjectSchema, NumberSchema } from 'yup';
import type { AnyObject } from 'yup/lib/types';
import type { Translations } from 'shared/utils/translations';
import type {
  PainThicknessOption,
  VehicleConditionPaintThicknessArea,
} from 'gql/graphql';
import type { PaintThicknessVersion } from './types';

const requiredStringNullable = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  return requiredString(optionalSchema, translations).nullable();
};
const requiredToggleNullable = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  return requiredToggle(optionalSchema, translations).nullable().default(null);
};

const requiredDamageOption = (
  optionalSchema: boolean,
  translations: Translations,
) => {
  const schema = optionalSchema
    ? yup.string().nullable()
    : yup.string().nullable().required(getRequiredMessage(translations));

  return schema.test('required', getRequiredMessage(translations), (value) => {
    if (optionalSchema) {
      return true;
    }
    if (value === 'GENERAL') {
      return false;
    }

    return !!value;
  });
};

function paintThicknessV1({
  optionalSchema,
  translations,
}: {
  optionalSchema: boolean;
  translations: Translations;
}) {
  return yup.object().shape({
    hood: requiredToggleNullable(optionalSchema, translations),
    right: requiredToggleNullable(optionalSchema, translations),
    left: requiredToggleNullable(optionalSchema, translations),
    roof: requiredToggleNullable(optionalSchema, translations),
    back: requiredToggleNullable(optionalSchema, translations),
  });
}

function paintThicknessV2({
  paintThicknessAreas,
  optionalSchema,
  translations,
}: {
  paintThicknessAreas: Array<PainThicknessOption> | null | undefined;
  optionalSchema: boolean;
  translations: Translations;
}) {
  const MIN = 1;
  const MAX = 999;
  const RANGE_ERROR_MESSAGE = window.Translator.trans(
    'a1-inspectionApp-quality-paint-thickness-range-validation',
    {
      min: MIN,
      max: MAX,
    },
  );

  return yup.object(
    paintThicknessAreas?.reduce((acc, { area, canBePlastic, canBeFabric }) => {
      acc[area] = yup.object({
        value: yup
          .number()
          .nullable(true)
          .when('isNotMeasurable', {
            is: (isNotMeasurable: boolean) =>
              (!canBePlastic && !canBeFabric) || !isNotMeasurable,
            then: (schema) => {
              const resultSchema = schema
                .min(MIN, RANGE_ERROR_MESSAGE)
                .max(MAX, RANGE_ERROR_MESSAGE);

              if (!optionalSchema) {
                return resultSchema.required(
                  translations.THIS_FIELD_CANNOT_BE_EMPTY,
                );
              }

              return resultSchema;
            },
          }),
      });

      return acc;
    }, {} as Record<VehicleConditionPaintThicknessArea, ObjectSchema<{ value: NumberSchema<number | null | undefined, AnyObject, number | null | undefined> }>>) ??
      {},
  );
}

const filesArray = (translations: Translations, optionalSchema: boolean) => {
  if (!optionalSchema) {
    return yup
      .array()
      .of(
        yup
          .mixed()
          .test(
            'isFile',
            translations.AT_LEAST_ONE_FILE_IS_REQUIRED,
            (value) => {
              return value instanceof File || value?.absoluteUrl;
            },
          ),
      )
      .test('required', getRequiredMessage(translations, 'file'), (value) =>
        value !== undefined ? value.length > 0 : false,
      );
  }
  return yup.array().of(
    yup
      .mixed()
      .test('isFile', translations.AT_LEAST_ONE_FILE_IS_REQUIRED, (value) => {
        return value instanceof File || value?.absoluteUrl;
      }),
  );
};

const HighlightsSchema = (
  translations: Translations,
  optionalSchema: boolean,
) =>
  yup.array().of(
    yup.object().shape({
      area: requiredStringNullable(optionalSchema, translations),
      part: requiredStringNullable(optionalSchema, translations),
      files: filesArray(translations, optionalSchema),
    }),
  );

const CarQualitySchema = (
  translations: Translations,
  optionalSchema: boolean,
  paintThicknessAreas: Array<PainThicknessOption> | null | undefined,
  paintThicknessVersion: PaintThicknessVersion,
  shouldUseNewAccidentModel: boolean,
) => {
  return yup.object({
    damages: yup.array().of(
      yup.object().shape({
        area: requiredDamageOption(optionalSchema, translations),
        part: requiredDamageOption(optionalSchema, translations),
        type: requiredDamageOption(optionalSchema, translations),
        severity: yup.string().notRequired(),
        isAccidentReason: yup.boolean().default(false),
        files: filesArray(translations, optionalSchema),
      }),
    ),
    ...(!shouldUseNewAccidentModel
      ? { highlights: HighlightsSchema(translations, optionalSchema) }
      : {}),
    paintThickness:
      paintThicknessVersion === 2
        ? yup.object({
            isPaintThicknessChecked: optionalSchema
              ? yup.bool().nullable()
              : yup.bool().nullable().required(translations.CHOOSE_ONE_OPTION),
            measurements: yup.mixed().when('isPaintThicknessChecked', {
              is: true,
              then: paintThicknessV2({
                paintThicknessAreas,
                optionalSchema,
                translations,
              }),
            }),
          })
        : paintThicknessV1({ optionalSchema, translations }),
  });
};

export { CarQualitySchema, paintThicknessV2, HighlightsSchema };
