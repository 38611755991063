import type { UseFormSetValue } from 'react-hook-form';
import type {
  AccidentArea,
  VehicleDamageArea,
  VehicleDamagePart,
  AccidentReason as AccidentReasonType,
} from 'gql/graphql';
import type { Translations } from 'shared/utils/translations';
import {
  AccidentReason,
  ACCIDENT_NOT_REPAIRED,
  REPAIRER_UNKNOWN,
  REPAIRER_OEM_FRANCHISE_DEALERSHIP,
  REPAIRER_INDEPENDENT,
  PAINT_THICKNESS_ACCIDENT_DAMAGE_PART_MAP,
  PAINT_THICKNESS_ACCIDENT_DAMAGE_TYPE_MAP,
  REASONS_WITH_DAMAGE_IMAGES,
  MAP_ACCIDENT_AREA_TO_DAMAGE_AREA,
  MAP_ACCIDENT_AREA_TO_WHEELS_PART,
} from './constants';
import type { Damage, Form, ReasonByArea } from './types';

const MAP_DAMAGE_AREA_TO_ACCIDENT_AREA: Partial<
  Record<VehicleDamageArea, AccidentArea>
> = {
  CONTROLS: 'INTERIOR',
  ENGINE: 'UNDERBODY',
  FRONT: 'FRONT',
  INTERIOR: 'INTERIOR',
  LEFT: 'LEFT',
  REAR: 'REAR',
  RIGHT: 'RIGHT',
  ROOF: 'ROOF',
  TRUNK: 'REAR',
  UNDERBODY: 'UNDERBODY',
};

const MAP_WHEEL_PART_TO_ACCIDENT_AREA: Partial<
  Record<VehicleDamagePart, AccidentArea>
> = {
  FRONT_LEFT_TIRE: 'LEFT',
  FRONT_LEFT_RIM: 'LEFT',
  REAR_LEFT_TIRE: 'LEFT',
  REAR_LEFT_RIM: 'LEFT',
  FRONT_RIGHT_TIRE: 'RIGHT',
  FRONT_RIGHT_RIM: 'RIGHT',
  REAR_RIGHT_TIRE: 'RIGHT',
  REAR_RIGHT_RIM: 'RIGHT',
};

function mapAccidentDamageAreaToAccidentArea(damages: Damage[]) {
  return [
    ...new Set(
      damages.reduce((acc, damage) => {
        if (!damage.area) {
          return acc;
        }

        let accidentArea = MAP_DAMAGE_AREA_TO_ACCIDENT_AREA[damage.area];

        if (!accidentArea && damage.area === 'WHEELS' && damage.part) {
          accidentArea = MAP_WHEEL_PART_TO_ACCIDENT_AREA[damage.part];
        }

        if (!damage.isAccidentReason || !accidentArea) {
          return acc;
        }

        return [...acc, accidentArea];
      }, [] as AccidentArea[]),
    ),
  ];
}

const getYesOrNoToggleValues = (translations: Translations) => [
  {
    label: translations.YES,
    value: true,
  },
  {
    label: translations.NO,
    value: false,
  },
];

function hasPaintThicknessReason(reasons: ReasonByArea) {
  return (
    AccidentReason.PanelsWithHighPaintThickness in reasons &&
    !!reasons[AccidentReason.PanelsWithHighPaintThickness]
  );
}

function hasInsuranceOrRepairInvoice(reasons: ReasonByArea) {
  return (
    AccidentReason.InsuranceOrRepairInvoice in reasons &&
    !!reasons[AccidentReason.InsuranceOrRepairInvoice]
  );
}

function shouldDisableNotRepairedOption(areas: Form['areas']) {
  if (!areas) {
    return false;
  }

  return Object.values(areas).some(
    (area) =>
      area &&
      (hasInsuranceOrRepairInvoice(area) || hasPaintThicknessReason(area)),
  );
}

function shouldDisableUnknownOption(areas: Form['areas']) {
  if (!areas) {
    return false;
  }

  return Object.values(areas).some(
    (area) =>
      area &&
      (hasInsuranceOrRepairInvoice(area) || hasPaintThicknessReason(area)),
  );
}

function shouldShowDocumentsUploader(
  areas: Form['areas'],
  repairedBy: Form['repair']['repairedBy'],
) {
  if (
    repairedBy &&
    [REPAIRER_OEM_FRANCHISE_DEALERSHIP, REPAIRER_INDEPENDENT].includes(
      repairedBy,
    )
  ) {
    return true;
  }

  return (
    areas &&
    Object.values(areas).some(
      (area) => area && hasInsuranceOrRepairInvoice(area),
    )
  );
}

function isDocumentsRequired(values: Form) {
  return (
    (values.areas &&
      Object.values(values.areas).some(
        (area) => area && hasInsuranceOrRepairInvoice(area),
      )) ||
    (values.repair?.repairedBy &&
      [REPAIRER_OEM_FRANCHISE_DEALERSHIP, REPAIRER_INDEPENDENT].includes(
        values.repair.repairedBy,
      ))
  );
}

function clearValueFromDisabledOptions(
  formValues: Form,
  setValue: UseFormSetValue<Form>,
) {
  if (
    shouldDisableNotRepairedOption(formValues.areas) &&
    formValues.repair?.repairStatus === ACCIDENT_NOT_REPAIRED
  ) {
    setValue('repair.repairStatus', null);
  }

  if (
    shouldDisableUnknownOption(formValues.areas) &&
    formValues.repair?.repairedBy === REPAIRER_UNKNOWN
  ) {
    setValue('repair.repairedBy', null);
  }
}

function filterDamagesForPaintThicknessReason(damages: Damage[]) {
  return damages.filter(
    ({ part, type, images, isAccidentReason }) =>
      isAccidentReason &&
      part &&
      PAINT_THICKNESS_ACCIDENT_DAMAGE_PART_MAP.includes(part) &&
      type &&
      PAINT_THICKNESS_ACCIDENT_DAMAGE_TYPE_MAP.includes(type) &&
      images &&
      images.length > 0,
  );
}

function filterDamagesForBodyGap(damages: Damage[]) {
  return damages.filter(
    ({ type, images, isAccidentReason }) =>
      isAccidentReason &&
      type &&
      !PAINT_THICKNESS_ACCIDENT_DAMAGE_TYPE_MAP.includes(type) &&
      images &&
      images.length > 0,
  );
}

function filterDamageImagesByReason(
  damagesByArea: Damage[],
  reason: AccidentReasonType,
) {
  switch (reason) {
    case AccidentReason.PanelsWithHighPaintThickness:
      return filterDamagesForPaintThicknessReason(damagesByArea).flatMap(
        (damage) => damage.images ?? [],
      );
    case AccidentReason.BodyGapsOrMisalignedPanel:
      return filterDamagesForBodyGap(damagesByArea).flatMap(
        (damage) => damage.images ?? [],
      );
    default:
      return [];
  }
}

function filterDamagesByArea(damages: Damage[], accidentArea: AccidentArea) {
  const filteredDamages = damages.filter(
    ({ area, images }) =>
      !!area &&
      MAP_ACCIDENT_AREA_TO_DAMAGE_AREA[accidentArea].includes(area) &&
      (images?.length ?? 0) > 0,
  );

  if (!['LEFT', 'RIGHT'].includes(accidentArea)) {
    return filteredDamages;
  }

  return filteredDamages.concat(
    damages.filter(
      ({ area, part }) =>
        area === 'WHEELS' &&
        !!part &&
        MAP_ACCIDENT_AREA_TO_WHEELS_PART[accidentArea]?.includes(part),
    ),
  );
}

function shouldDisableReason(
  reason: AccidentReasonType,
  damagesByArea: Damage[],
) {
  const shouldUseDamageImages = REASONS_WITH_DAMAGE_IMAGES.includes(reason);
  const filteredDamages =
    reason === AccidentReason.PanelsWithHighPaintThickness
      ? filterDamagesForPaintThicknessReason(damagesByArea)
      : filterDamagesForBodyGap(damagesByArea);

  return shouldUseDamageImages && filteredDamages.length === 0;
}

function areaIsEmpty(area: AccidentArea, areas: Form['areas']) {
  const accidentArea = areas?.[area];

  if (!accidentArea) {
    return true;
  }

  const reasons = Object.values(accidentArea);

  if (!reasons) {
    return true;
  }

  return reasons.every((reason) => !reason);
}

export {
  mapAccidentDamageAreaToAccidentArea,
  getYesOrNoToggleValues,
  hasInsuranceOrRepairInvoice,
  shouldDisableNotRepairedOption,
  shouldDisableUnknownOption,
  shouldShowDocumentsUploader,
  isDocumentsRequired,
  clearValueFromDisabledOptions,
  filterDamagesForPaintThicknessReason,
  filterDamagesForBodyGap,
  shouldDisableReason,
  filterDamagesByArea,
  areaIsEmpty,
  filterDamageImagesByReason,
};
