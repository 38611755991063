import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import { yupResolver } from '@hookform/resolvers/yup';
import { apm } from '@elastic/apm-rum';
import { withTransaction } from '@elastic/apm-rum-react';

import { Button, ButtonTypes } from '@auto1-ui/button';

import { appRoutes } from 'appRoutes';
import styles from 'shared/components/NavigationFooter/index.module.scss';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import { scrollToError } from 'shared/utils/scrollToError';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import Loader from 'shared/components/Loader';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import { useCarQualityInitialDataQuery } from 'gql/graphql';
import type {
  CarQualityInitialDataQuery,
  PainThicknessOption,
} from 'gql/graphql';
import { filterNull } from 'shared/utils/filterNull';
import { AlertBar } from 'shared/components/AlertBar';
import type { Translations } from 'shared/utils/translations';

import { PaintThickness } from './PaintThickness';
import { PaintThicknessV2 } from './PaintThicknessV2';
import { Damages } from './Damages';
import { Highlights } from './Highlights';
import { useCarQualityUpdater } from './hooks/useCarQualityUpdater';
import { useProgress } from './hooks/useProgress';
import { CarQualitySchema } from './car-quality.schema';
import { RoutesEnum } from '../../shared/utils/route';
import { Placeholder } from './Placeholder';
import { mapDataToFormValues, mapDataToMutation } from './helpers';
import type { PaintThicknessVersion, Form } from './types';

type Props = {
  qaIdPrefix?: string;
};

function getPaintThicknessAreas(
  paintThicknessVersion: PaintThicknessVersion,
  inspection: CarQualityInitialDataQuery['inspection'],
) {
  return paintThicknessVersion === 1
    ? null
    : inspection?.fields?.vehicle?.paintThickness;
}

const CarQuality: React.FC<Props> = ({ qaIdPrefix = 'car-quality' }) => {
  const history = useHistory();
  const location =
    useLocation<{
      prev?: {
        pathname: string;
        hash: string;
      };
      sideMenuNavigation?: boolean;
    }>();
  const { translations } = useTranslation();
  const {
    removeStepFromNotCompleted,
    inspectionId,
    shouldUseNewAccidentModel,
  } = useInspection();
  const { submitTracking } = useSubmitTracking('carQuality');
  const formContext = React.useRef<{
    submitContext?: 'save' | 'submit';
    paintThicknessAreas?: Array<PainThicknessOption | null> | null;
  }>({});
  const formHandlers = useForm<Form>({
    context: formContext,
    resolver: (data, context, options) => {
      const isOptionalSchema = context?.current.submitContext === 'save';
      const paintThicknessVersion = data.paintThickness.version;
      const paintThicknessAreas =
        paintThicknessVersion === 2
          ? context?.current.paintThicknessAreas?.filter(filterNull)
          : null;

      return yupResolver(
        CarQualitySchema(
          translations,
          isOptionalSchema,
          paintThicknessAreas,
          paintThicknessVersion,
          shouldUseNewAccidentModel,
        ),
      )(data, context, options);
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    getValues,
    register,
    unregister,
  } = formHandlers;
  const paintThicknessVersion = formHandlers.watch('paintThickness.version');

  useScrollToSection(location.hash);
  useSideMenuNavigationTracking(
    location.state && location.state.prev,
    location,
  );

  const { scroll } = useScrollToSection(location.hash);
  const { data, loading } = useCarQualityInitialDataQuery({
    variables: { inspectionId },
    onCompleted: (resultData) => {
      const formValues = mapDataToFormValues(
        resultData?.inspection,
        shouldUseNewAccidentModel,
      );
      formContext.current.paintThicknessAreas = getPaintThicknessAreas(
        paintThicknessVersion,
        resultData?.inspection,
      );
      formHandlers.reset(cloneDeep(formValues));
      setTimeout(scroll);
    },
  });
  const [updateCarQuality, { loading: mutationLoading, error: mutationError }] =
    useCarQualityUpdater(
      inspectionId,
      data?.inspection?.car?.stockNumber ?? null,
      paintThicknessVersion,
      shouldUseNewAccidentModel,
    );
  const [uploadError, setUploadError] = React.useState('');
  const serverError = mutationError?.graphQLErrors?.[0]?.message ?? uploadError;
  const setProgress = useProgress();

  const onError = () => {
    const formData = getValues();
    scrollToError();
    setProgress(formData);
  };
  const onSubmit = async (values: Form) => {
    const optionalSchema = formContext.current.submitContext === 'save';
    const redirect = formContext.current.submitContext === 'submit';

    try {
      await updateCarQuality({
        ...mapDataToMutation(values, shouldUseNewAccidentModel),
        optionalSchema,
      });
      formHandlers.reset(getValues());
      removeStepFromNotCompleted(RoutesEnum.CAR_QUALITY);

      if (redirect) {
        submitTracking();

        if (shouldUseNewAccidentModel) {
          history.push(appRoutes.accidents(`${inspectionId}`, true));
        } else {
          history.push(appRoutes.tiresBrakes(`${inspectionId}`, true));
        }
      }
    } catch (e) {
      apm.captureError(e as Error);
      setUploadError((e as Error).message);
    }
  };

  React.useEffect(() => {
    register('paintThickness.version', { value: paintThicknessVersion });

    return () => {
      unregister('paintThickness.version');
    };
  }, [paintThicknessVersion, register, unregister]);

  if (loading || !data?.inspection) {
    return <Placeholder />;
  }

  return (
    <LayoutContainer>
      <FormProvider {...formHandlers}>
        {paintThicknessVersion === 2 ? (
          <PaintThicknessV2
            qaIdPrefix={qaIdPrefix}
            areas={data?.inspection?.fields?.vehicle?.paintThickness}
          />
        ) : (
          <PaintThickness qaIdPrefix={qaIdPrefix} />
        )}
        <Damages
          qaIdPrefix={`${qaIdPrefix}-damages`}
          paintThicknessVersion={paintThicknessVersion}
        />
        {!shouldUseNewAccidentModel && (
          <Highlights
            inspectionId={inspectionId}
            qaIdPrefix={`${qaIdPrefix}-highlights`}
          />
        )}
        <NavigationFooter
          backButtonLink={appRoutes.carPictures(`${inspectionId}`, true)}
          qaPrefix={qaIdPrefix}
          rightAreaContent={
            <>
              <Button
                type={ButtonTypes.button}
                qaId={`${qaIdPrefix}-footer-save-link`}
                onClick={() => {
                  formContext.current.submitContext = 'save';
                  handleSubmit(onSubmit, onError)();
                }}
                theme="outlined"
                isActivated={false}
                extraClass={styles.saveButton}
                isDisabled={isSubmitting}
              >
                {translations.SAVE}
                {isSubmitting &&
                  formContext.current.submitContext === 'save' && (
                    <Loader extraClass={styles.saveButtonLoader} />
                  )}
              </Button>
              <Button
                type={ButtonTypes.button}
                qaId={`${qaIdPrefix}-footer-next-link`}
                isActivated={false}
                onClick={() => {
                  formContext.current.submitContext = 'submit';
                  handleSubmit(onSubmit, onError)();
                }}
                theme="orange"
                extraClass={styles.nextButton}
                isDisabled={isSubmitting}
              >
                {translations.NEXT}
                {(isSubmitting || mutationLoading) &&
                  formContext.current.submitContext === 'submit' && (
                    <Loader extraClass={styles.nextButtonLoader} />
                  )}
              </Button>
            </>
          }
          bottomAreaContent={
            serverError && (
              <AlertBar
                type="error"
                message={
                  serverError in translations
                    ? translations[serverError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={styles.alertBar}
              />
            )
          }
        />
        <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
          <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
        </RouteLeavingGuard>
      </FormProvider>
    </LayoutContainer>
  );
};

const CarQualityWithTransaction = withTransaction(
  'CarQuality',
  'component',
)(CarQuality);

export { CarQualityWithTransaction as CarQuality };
